<template>
  <v-container
    id="speakers"
    fluid
    tag="section"
  >
    <base-v-component
      heading="Formateurs"
    />
      <v-row>
        <v-col
          cols="6"
          sm="4"
          md="3"
          xl="2"
          v-for="speaker in speakers"
              :key="speaker.id"
        >
          <template>
            <v-card
              class="mx-auto my-12"
              max-width="374"
              @click="$router.push({ name: 'FormateurDetail', params: { id: speaker.id } })"
            >
              <img
                class="avatar-speaker"
                :src="speaker.imgPhoto"
                @error="getAvatar($event, speaker)"
              />

              <v-card-title class="mt-2 speaker-name">
                {{ speaker.sexe === 'm' ? 'M.' : 'Mme' }} {{ speaker.prenom }} {{ speaker.nom }}
              </v-card-title>

              <v-card-text>
                <div class="my-1">
                  <div v-if="speaker.societe">
                    <span class="badge badge-societe">
                      {{ speaker.societe.raisonSociale }}
                    </span>
                  </div>
                  <div v-else>
                    <span class="badge badge-no-societe">
                      Formateur interne
                    </span>
                  </div>
                </div>
                <div class="mt-2">
                  <div class="my-1 subtitle-1">
                    <v-icon
                      small
                      v-if="speaker.adresse && speaker.adresse.ville || speaker.adresse && speaker.adresse.departement"
                    >
                      mdi-map-marker
                    </v-icon>
                    <span v-if="speaker.adresse && speaker.adresse.ville">
                      {{ speaker.adresse.ville }}
                    </span>
                    <span v-if="speaker.adresse && speaker.adresse.departement">
                      ({{ speaker.adresse.departement }})
                    </span>
                  </div>
                </div>
              </v-card-text>

              <v-divider class="mx-2"></v-divider>

              <v-card-text>
                <v-chip-group
                  column
                >
                  <v-chip
                    v-for="competence in speaker.competences.slice(0, 4)"
                    :key="competence.name"
                  >
                    {{ competence.name }}
                  </v-chip>
                  <v-chip
                    v-if="speaker.competences.length > 4"
                  >
                    +{{ speaker.competences.length - 4 }}
                  </v-chip>
                </v-chip-group>
              </v-card-text>
            </v-card>
          </template>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
        >
          <div
            class="d-flex justify-end mb-3"
          >
            <v-btn
              color="primary"
              x-large
              :to="{ name: 'CreerFormateur'}"
            >
              <v-icon class="mr-2">mdi-plus-circle</v-icon>
              Ajouter un nouveau formateur
            </v-btn>
          </div>
        </v-col>
      </v-row>

  </v-container>
</template>

<script>
  import { mapMutations } from 'vuex';

  export default {
    data() {
      return {
        speakersPhotosUrl: process.env.VUE_APP_SPEAKERS_PHOTOS_BASE_URL,
        speakers: [],
      }
    },

    created() {
      this.axios.get('/intervenants')
        .then((res) => {
          const speakers = res.data.filter((s) => s.actif).sort((a, b) => a.nom.localeCompare(b.nom));
          const requests = speakers.map(speaker => {
            if (speaker.photo) {
              return this.axios.get(`/intervenants/${speaker.id}/getPhoto/300`, { responseType: 'arraybuffer' })
                .then(res => {
                  speaker.imgPhoto = 'data:image/png;base64,' + Buffer.from(res.data, 'binary').toString('base64');
                })
                .catch(e => {
                  speaker.imgPhoto = ''; // En cas d'erreur, définit une chaîne vide pour imgPhoto
                });
            } else {
              speaker.imgPhoto = this.getAvatar(speaker);
              return Promise.resolve(); // Résout avec une promesse vide si pas de photo
            }
          });

          return Promise.all(requests).then(() => speakers);
        })
        .then(speakers => {
          this.speakers = speakers;
        })
        .catch(e => {
          this.setSnack({ text: 'Erreur lors de la récupération des formateurs', type: 'warn' });
        });
    },

    methods: {
      getAvatar(speaker) {
        return speaker.sexe === 'm' ? require('@/assets/avatar-man.png') : require('@/assets/avatar-woman.png');
      },

      ...mapMutations({
        setSnack: 'SET_SNACK',
      }),
    },

  }
</script>

<style scoped>
  #speakers >>> .speaker-name {
    word-break: break-word;
  }

  #speakers >>> .avatar-speaker {
    height: 200px;
    object-fit: cover;
    width: 100%;
  }

  #speakers >>> .badge {
    font-size: 0.6rem;
    border-radius: 4px;
    padding: 4px 6px;
    font-weight: 500;
  }

  #speakers >>> .badge-societe {
    background-color: #989696cc;
    color: #fff;
  }

  #speakers >>> .badge-no-societe {
    background-color: #5d3fd3ad;
    color: #fff;
  }
</style>
